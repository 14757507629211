<template>
   <section class="container-fluid text-left container">
        <form @submit.prevent="userRegistration">
            <h3 class="text-center" style="color: rgb(92, 82, 162);">Sign Up</h3>

            <div class="form-group mt-2 mb-2 ">
                <label>Name</label>
                <input type="text" class="form-control form-control-lg" v-model="user.name" />
            </div>

            <div class="form-group mt-2 mb-2 ">
                <label>Email</label>
                <input type="email" class="form-control form-control-lg" v-model="user.email" />
            </div>

            <div class="form-group mt-2 mb-2   ">
                <label>Password</label>
                <input type="password" class="form-control form-control-lg" v-model="user.password" />
            </div>

            <button type="submit" class="btn btn-dark btn-lg btn-block mt-2 mb-2 " style="background: rgb(92, 82, 162);">
               Sign Up
            </button>

            <p class="forgot-password text-right mt-2 mb-2 ">
                Already registered 
                <router-link to="/login">sign in?</router-link>
            </p>
        </form>
    </section>
</template>


<script>
import firebase from 'firebase'
import { createProject, createGroup } from '@/firebase'

export default {
  name: 'RegestrationPage',
  
 data() {
    return {
      user: {
        name: '',
        email: '',
        password: ''
      }
    };
  },
  methods: {
    userRegistration() {
      firebase
      .auth()
      .createUserWithEmailAndPassword(this.user.email, this.user.password)
      .then((res) => {
        res.user
          .updateProfile({
            displayName: this.user.name
          })
          .then(() => {
            this.createNewProject(res.user.uid)
            //alert(user.id)
            this.$router.push('/tasks')
          });
      })
      .catch((error) => {
         alert(error.message);
      });
    },

    async createNewProject(uid){
      
      let new_project_data = {
                              name:                  "Project 1",
                              start_date:            "01/11/2021",
                              end_date:              "02/11/2021",
                              days_number:           2,
                              note:                  "",

                              show_checked: true, 
                              show_priority: true, 
                              show_status: true, 
                              show_recurrence: true, 
                              show_time: true, 
                              show_start_date: true, 
                              show_end_date: true, 
                              show_completed_tasks: true, 
                              show_timer: true, 
                              show_progress_bar: true, 
                              show_fast_task_addition: true, 
                              uid : uid
                            }
        let newProID = 0
        await createProject(new_project_data).then(function(data) {
                        newProID = data.id  //get the new created doc id 
                        })

        // create group
        await createGroup({
                name        : "Group 1",
                order       : 1,
                project_id  : newProID,
                uid         : uid
                })
        
    }
  }
}

</script>
