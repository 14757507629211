<template>
      <div class="modal-mask"  style="display: block;" aria-modal="true" role="dialog">
          <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" >  
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title "> 
                            Project
                        </h5>
                    </div>

                    <div class="modal-body">
                        <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Details</button>
                            <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-settings" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Settings</button>
                        </div>
                        </nav>

                        <div class="tab-content" id="nav-tabContent" role="dialog" >
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div class="mb-2">
                                    <label class="form-label">Project Name </label>
                                    <input v-model="name" class="form-control" placeholder="Project Name">
                                </div>
                                <div class="mb-2">
                                    <label class="form-label">Start Date </label>
                                    <input v-model="start_date" @change="updateNomOfDays" type="date" class="form-control">
                                </div>   
                                <div class="mb-2">
                                    <label  class="form-label">End Date </label>
                                    <input v-model="end_date" @change="updateNomOfDays" type="date" class="form-control">
                                </div> 
                                <div class="mb-2">
                                    <label  class="form-label">No. of Days </label>
                                    <input v-model="days_number" type="number" disabled class="form-control">
                                </div>                                               
                                <div class="mb-2">
                                    <label  class="form-label">Note</label>
                                    <textarea v-model="note" class="form-control" rows="3"></textarea>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="nav-settings" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <h6 class="mt-3 mb-3"> Task Table Options:  </h6>

                                <div class="form-check form-switch mt-2">
                                    <input v-model='show_checked' class="form-check-input" type="checkbox" checked>
                                    <label class="form-check-label" for="flexSwitchCheckDefault"> Show "Check" column </label>
                                </div>
                                <div class="form-check form-switch mt-2">
                                    <input v-model='show_priority' class="form-check-input" type="checkbox" checked>
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Show "Priority" column</label>
                                </div>
                                <div class="form-check form-switch mt-2">
                                    <input v-model='show_status' class="form-check-input" type="checkbox" >
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Show "Status" column </label>
                                </div>
                                <div class="form-check form-switch mt-2">
                                    <input v-model='show_recurrence' class="form-check-input" type="checkbox" >
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Show "Recurrence" column</label>
                                </div>
                                <div class="form-check form-switch mt-2">
                                    <input v-model='show_time' class="form-check-input" type="checkbox" checked>
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Show "Time" column</label>
                                </div> 
                                <div class="form-check form-switch mt-2">
                                    <input v-model='show_start_date' class="form-check-input" type="checkbox" checked>
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Show "Start Date" column</label>
                                </div> 
                                <div class="form-check form-switch mt-2">
                                    <input v-model='show_end_date' class="form-check-input" type="checkbox" checked>
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Show "End Date" column</label>
                                </div> 
                                <div class="form-check form-switch mt-2">
                                    <input v-model='show_completed_tasks' class="form-check-input" type="checkbox" checked>
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Show Completed Tasks</label>
                                </div>
                                <hr>

                                <h6 class="mt-2"> Inerface Options:  </h6>
                                
                                <div class="form-check form-switch mt-3">
                                    <input v-model='show_timer' class="form-check-input" type="checkbox" >
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Show Stopwatch</label>
                                </div>
                                <div class="form-check form-switch mt-2">
                                    <input v-model='show_progress_bar' class="form-check-input" type="checkbox" checked>
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Show progress bar</label>
                                </div>
                                <div class="form-check form-switch mt-2">
                                    <input v-model='show_fast_task_addition' class="form-check-input" type="checkbox" checked>
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Show fast task addition</label>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                    <div class="modal-footer" style="display: block !important; text-align:center;">
                        <button type="button" class="btn btn-success btn-sm" @click="submitProject()"> Save</button>
                        <button type="button" class="btn btn-secondary btn-sm" @click="$emit('close')"> Close</button>
                        <button v-if="project_data.id" type="button" class="btn btn-danger btn-sm" @click="deletePro(); $emit('close') "> Delete</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { createProject, updateProject, createGroup, deleteProject } from '@/firebase'
//import {  ref } from 'vue'

export default {
    props:{
        project_operation_type: String,
        project_data: Object, // will be used to get the edited project details from the parent component
        getNewProjectData: Function,
    },
    emits: ['close'], 
    data() {
        return { 
            //projec_details: this.project_data,
            id:     this.project_data.id, 
            name:   this.project_data.name, 
            start_date: this.project_data.start_date, 
            end_date: this.project_data.end_date, 
            days_number:   this.project_data.days_number, 
            note:   this.project_data.note, 

            show_checked : this.project_data.show_checked,
            show_priority : this.project_data.show_priority,
            show_status : this.project_data.show_status,
            show_recurrence : this.project_data.show_recurrence,
            show_time : this.project_data.show_time,
            show_start_date : this.project_data.show_start_date,
            show_end_date : this.project_data.show_end_date,
            show_completed_tasks : this.project_data.show_completed_tasks,

            show_timer: this.project_data.show_timer,
            show_progress_bar: this.project_data.show_progress_bar,
            show_fast_task_addition: this.project_data.show_fast_task_addition,


            uid:  this.project_data.uid,

            new_project_data:{}
        }
    },
    created() {
        
        if(this.project_operation_type === "new"){ 
            let today = new Date()
            let dd = ("0" + ( today.getDate()) ).slice(-2)
            let mm = ("0" + ( today.getMonth()) ).slice(-2)
            let yyyy = today.getFullYear()
            today = yyyy + '-' + mm + '-' + dd 
            this.start_date =    today
            this.end_date =    today

            this.days_number = 1
            this.note = ""
        }
            
    },

    methods: {
        updateNomOfDays (){
            if(this.end_date && this.start_date){
                this.days_number = Math.floor((Date.parse(this.end_date) - Date.parse(this.start_date)) / 86400000) +1
            }else{
                this.days_number = 0
            }
        },

        async deletePro() {
            if(confirm("Do you really want to delete the project?")){
                await deleteProject(this.id)
                this.getNewProjectData("")
            }

            
        },

        async submitProject(){
            if(this.name.length === 0 || this.days_number < 1) {
                alert("Please, enter valid data")
                return;
            }

            this.new_project_data = { 
                                    name:                  this.name,
                                    start_date:            this.start_date,
                                    end_date:              this.end_date,
                                    days_number:           this.days_number,
                                    note:                  this.note,

                                    show_checked :         this.show_checked,
                                    show_priority :        this.show_priority,
                                    show_status :          this.show_status,
                                    show_recurrence :      this.show_recurrence,
                                    show_time :            this.show_time,
                                    show_start_date :      this.show_start_date,
                                    show_end_date :        this.show_end_date,
                                    show_completed_tasks : this.show_completed_tasks,

                                    show_timer: this.show_timer,
                                    show_progress_bar: this.show_progress_bar,
                                    show_fast_task_addition: this.show_fast_task_addition,
                                    uid :                  this.uid
                                    }

            if (this.project_operation_type === "new") { 

                if(typeof(this.new_project_data.show_checked) === 'undefined') { this.new_project_data.show_checked = true }
                if(typeof(this.new_project_datashow_priority) === 'undefined') { this.new_project_data.show_priority = true }
                if(typeof(this.new_project_data.show_status) === 'undefined') { this.new_project_data.show_status = false }
                if(typeof(this.new_project_data.show_recurrence) === 'undefined') { this.new_project_data.show_recurrence = false }
                if(typeof(this.new_project_data.show_time) === 'undefined') { this.new_project_data.show_time = true }
                if(typeof(this.new_project_data.show_start_date) === 'undefined') { this.new_project_data.show_start_date = true }
                if(typeof(this.new_project_data.show_end_date) === 'undefined') { this.new_project_data.show_end_date = true }
                if(typeof(this.new_project_data.show_completed_tasks) === 'undefined') { this.new_project_data.show_completed_tasks = true }

                if(typeof(this.new_project_data.show_timer) === 'undefined') { this.new_project_data.show_timer = false }
                if(typeof(this.new_project_data.show_progress_bar) === 'undefined') { this.new_project_data.show_progress_bar = true }
                if(typeof(this.new_project_data.show_fast_task_addition) === 'undefined') { this.new_project_data.show_fast_task_addition = true }


                let newProID = 0
                //convert array to obj
                //let ob = Object.assign({}, this.projec_details);
                //ob = this.projec_details
                // create project
                await createProject(this.new_project_data).then(function(data) {
                                    newProID = data.id  //get the new created doc id 
                                    })

                // create group
                await createGroup({
                        name        : "Group 1",
                        order       : 1,
                        project_id  : newProID,
                        uid         :this.uid
                    })

                this.new_project_data.id = newProID         
                this.getNewProjectData(this.new_project_data)

            } else {

                this.new_project_data.id = this.project_data.id
                await updateProject(this.new_project_data.id, this.new_project_data)
                this.getNewProjectData(this.new_project_data)
            }
        },

    },//end methods
}

</script>

<style scoped>
.btn{
  border-radius: 0px;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 24px;
  border: 1px solid #888;
 
}
</style>