<template>

<div class='a'>

  <div class='c'>
      <div class='_404'>404</div>
      <hr>
      <div class='_3'>!!!افه</div>
      <div class='_3'>الصفحة غير موجودة  </div>
      <div class='_3'>  😁  </div>
      
      <div class='_1'>THE PAGE</div>
      <div class='_2'>WAS NOT FOUND</div>

      <router-link to="/" class="btn" style> BACK TO HOME </router-link>
     
  </div>
</div>

</template>

<script>
export default {
  name: "404Page"
};
</script>

<style scoped>
  .a{
      background: #8e7eb7;
      color:#fff;
      font-family: 'Open Sans', sans-serif;
      height: 800px;
      overflow: hidden;
  }
  .c{
      text-align: center;
      display: block;
      position: relative;
      width:80%;
      margin:25px auto;
  }
  ._404{
      font-size: 5em;
      position: relative;
      display: inline-block;
      z-index: 2;
     
      letter-spacing: 15px;
  }
  ._1{
      margin-top: 20px;
      text-align:center;
      display:block;
      position:relative;
      letter-spacing: 8px;
      font-size: 2.5em;
      line-height: 80%;
  }
  ._2{
      margin-top: 20px;
      margin-bottom: 20px;
      text-align:center;
      display:block;
      position: relative;
      font-size: 22px;
  }
  ._3{
      text-align:center;
      display:block;
      position:relative;
      font-size: 2em;
      line-height: 80%;
      margin-top: 10px;
      margin-bottom: 10px;
  }

  .text{
      font-size: 70px;
      text-align: center;
      position: relative;
      display: inline-block;
      margin: 19px 0px 0px 0px;
      /* top: 256.301px; */
      z-index: 3;
      width: 100%;
      line-height: 1.2em;
      display: inline-block;
  }


  .btn{
      background-color: rgb( 255, 255, 255 );
      position: relative;
      display: inline-block;
      padding: 10px;
      z-index: 5;
      font-size: 25px;
      margin:0 auto;
      color:#8e7eb7;
      text-decoration: none;
      margin-right: 10px
  }
  .right{
      float:right;
      width:60%;
  }

  hr{
      border-top: 5px solid #fff;
      text-align: center;
      margin: 0px auto;
    }

</style>


