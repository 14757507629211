<template>

      <div class="modal-mask"  style="display: block;" aria-modal="true" role="dialog">
          <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" >  
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title "> 
                  Group Details
                </h5>
              </div>
              <div class="modal-body">
                <div class="mb-2">
                    <label class="form-label">Group Name </label>
                    <input v-model="gr_name" class="form-control" placeholder="Group Name">
                </div>
                <div class="mb-2">
                    <label class="form-label"> Order </label>
                    <input v-model="gr_order"  class="form-control" placeholder="Order">
                </div>   
              </div>
              <div class="modal-footer" style="display: block !important; text-align:center;">
                <button type="button" class="btn btn-success btn-sm" @click="submitGroup()" > Save</button>
                <button type="button" class="btn btn-secondary btn-sm" @click="$emit('close')" > Close</button>
                <button v-if="group_data.id" type="button" class="btn btn-danger btn-sm" @click="deleteGr(); $emit('close') "> Delete</button>
              </div>
            </div>
          </div>
      </div>
  
</template>

<script>
import { createGroup, updateGroup, deleteGroup } from '@/firebase'
//import {  ref } from 'vue'

export default {
    props:{
        group_operation_type: String,
        group_data: Object, // will be used to get the edited group details from the parent component
        getNewGroupData: Function,
    },
    emits: ['close'], 
    data() {
        return { 
            gr_id:         this.group_data.id, 
            gr_name:       this.group_data.name, 
            gr_order:      this.group_data.order, 
            gr_project_id: this.group_data.project_id,
            gr_uid:  this.group_data.uid,

            new_group_data:{}
        }
    },

    methods: {
        async deleteGr() {
          if(confirm("Do you really want to delete the group?")){
            await deleteGroup(this.group_data.id)
            this.getNewGroupData("")
          }
        },

        async submitGroup(){
            if(this.gr_name.length === 0 ) {
                alert("Please, enter valid data")
                return;
            }
        
            this.new_group_data = {   
                                      name:        this.gr_name,
                                      order:       this.gr_order,
                                      project_id:  this.gr_project_id,
                                      uid: this.gr_uid
                                    }

            if (this.group_operation_type === "new") { 
                //create new project
                let newGroID = 0
                
                await createGroup(this.new_group_data).then(function(data) {
                                            newGroID = data.id  //get the new created doc id 
                                    })

                this.new_group_data.id = newGroID           
                this.getNewGroupData(this.new_group_data)

            } else {
                this.new_group_data.id = this.group_data.id  
                await updateGroup( this.group_data.id, this.new_group_data)
                this.getNewGroupData(this.new_group_data)
            }
            this.gr_id=''
            this.gr_name=''
            this.gr_order=''
            this.gr_uid=''
        },
    },//end methods
}

</script>

<style scoped>
.btn{
  border-radius: 0px;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 24px;
  border: 1px solid #888;
 
}
</style>