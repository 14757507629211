import { createApp } from 'vue'
import { library, dom } from'@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from'@fortawesome/vue-fontawesome'
import { far } from'@fortawesome/free-regular-svg-icons'
import { fas } from'@fortawesome/free-solid-svg-icons'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.min.js"
//import 'bootstrap-icons/font/bootstrap-icons'

library.add(fas, far);
dom.watch()

createApp(App)
    .component('font-awesome-icons', FontAwesomeIcon)
    .use(router)
    .mount('#app')






