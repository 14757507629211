import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import LoginPage from '@/views/LoginPage.vue'
import HelpPage from '@/views/HelpPage.vue'
import PageNotFound from '@/views/404Page.vue'
import RegestrationPage from '@/views/RegestrationPage.vue'
import TasksPage from '@/components/TaskList.vue'
import firebase from 'firebase';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/regestration',
    name: 'RegestrationPage',
    component: RegestrationPage
  },
  {
    path: '/help',
    name: 'HelpPage',
    component: HelpPage
  },
  {
    
    path: '/:pathMatch(.*)*', 
    name: 'PageNotFound',
    component: PageNotFound
  },

  {
    path: '/tasks',
    name: 'TasksPage',
    component: TasksPage,
    meta: {
      authRequired: true,
    },
  },
  //{
  //  path: '/tasks/:id',
  //  name: 'Task',
  //  component: Tasks,
    //meta: {
   //   authRequired: true,
  //  },
  //},



]
 
const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.authRequired)) {
    firebase.auth().onAuthStateChanged((user) =>{
      if (user ) {
        
          next();
          
      } else {
          alert('You must be logged in to see this page');
          next({
              path: '/',
          });
      }
    })
  } else {
      next();
  }
});

export default router
