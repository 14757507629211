<template>
    <section class="container-fluid text-left container">
        <form @submit.prevent="login">     
            <h3 class="text-center" style="color: rgb(92, 82, 162);">Login</h3>
            <div class="form-group mt-2 mb-2 ">
                <label>Email</label>
                <input type="email" class="form-control form-control-lg" v-model="email" />
            </div>

            <div class="form-group mt-2 mb-2   ">
                <label>Password</label>
                <input type="password" class="form-control form-control-lg" v-model="password" />
            </div>

            <button type="submit" class="btn btn-dark btn-lg btn-block mt-2 mb-2 " style="background: rgb(92, 82, 162);">
                Login
            </button>
        </form> 
    </section>
</template>


<script>
import firebase from 'firebase'

export default {
  name: 'LoginPage',
    methods: {
        login() {
            firebase
            .auth()
            .signInWithEmailAndPassword(this.email, this.password)
            .then(() => {
                //alert(this.user.id)
                this.$router.push('/tasks');
            })
            .catch(error => {
                alert(error.message);
            });
        },
    },
}
</script>
