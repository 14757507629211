<template>

    <div class="container-fluid pt-2 ps-3 pe-3">
      <header v-if="this.$route.name !== 'TasksPage'" >
          <div class="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
            <a href="/" class="d-flex align-items-center text-dark text-decoration-none">
              <img class="me-2"  :src="'/favicon-32x32.png'" >

               <div
                    style="
                      margin-right: 5px;
                      font-weight: 1000;
                      font-size: 2.3rem;
                      font-family: var(--bs-body-font-family); 
                      color: #5c52a2;">
                   Task7.co  
                </div> 
                <span class="mb-3 ">  Beta  </span>
            </a>

            <nav class="d-inline-flex mt-2 mt-md-0 ms-md-auto">
              <router-link class="me-3 py-2 text-dark text-decoration-none" v-if="this.$route.name !== 'Home' " to="/">Home</router-link> 
              <router-link class="me-3 py-2 text-dark text-decoration-none" to="/tasks">Tasks</router-link> 
              <a class="me-3 py-2 text-dark text-decoration-none" href="./Task7_manual.pdf">Help</a>
              <router-link class="me-3 py-2 text-dark text-decoration-none" v-if="!user" to="/login">Login</router-link> 
              <router-link class="me-3 py-2 text-dark text-decoration-none" v-if="!user" to="/regestration">Register</router-link> 

            </nav>
          </div>
      </header> 

      <router-view />
    </div>
 
</template>

<script>

import firebase from 'firebase'
import { provide, ref } from 'vue'

export default {
  name: 'App',
  data () {
    return {
      user: null
    }

  },

  setup() {
    const user_info_id = ref('id')
    const user_info_name = ref('name')
    const user_info_email = ref('email')
    //const user_info = reactive(this.user)

    provide('user_info_id', user_info_id)
    provide('user_info_name', user_info_name)
    provide('user_info_email', user_info_email)
    return {
      user_info_id, user_info_name, user_info_email
      }
  },

 
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.user_info_id = user.uid
        this.user_info_name = user.displayName
        this.user_info_email = user.email
 
      } else {
        this.user = null;
      }
    });
  },

  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push('/');
        })
        .catch(error => {
          alert(error.message);
          this.$router.push('/');
        });
    },
  },

}
</script>

<style>

body {
  background-image: linear-gradient(
       180deg, #eee, #fff 100px, #fff) !important;
  }
</style>