import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/database'
import { reactive, ref, onUnmounted } from 'vue'

const config = {
  apiKey: "AIzaSyAleZBij2lyhIWqIFdDM5pif2orUsOPckE",
  authDomain: "vue-todo-1137a.firebaseapp.com",
  projectId: "vue-todo-1137a",
  storageBucket: "vue-todo-1137a.appspot.com",
  messagingSenderId: "178784734307",
  appId: "1:178784734307:web:2ead23663af31fb19cbc56",
  measurementId: "G-VMENTNNGER"
} 

const firebaseApp = firebase.initializeApp(config)

const db = firebaseApp.firestore()
//const usersCollection = db.collection('users')
const projectsCollection = db.collection('projects')
const tasksCollection = db.collection('tasks')
const settingsCollection = db.collection('settings')


export const updateUser = (user_name) => {
   firebase.auth().currentUser.updateProfile({displayName: user_name})
}

//export const deleteUser = id => {
//  return usersCollection.doc(id).delete()
//}

//export const useLoadUsers = () => {
 // const users = ref([])
 // const close = usersCollection.onSnapshot(snapshot => {
  //  users.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
 // })
 // onUnmounted(close)
//  return users
//}
///////////////////////////////////
export const createProject = project => {
  return projectsCollection.add(project)
}

export const updateProject = (id, project) => {
  return projectsCollection.doc(id).update(project)
}

export const deleteProject = id => {
  var tasks = tasksCollection.where("project_id","==",id)
  tasks.get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      doc.ref.delete();
    });
  });
  var groups = groupsCollection.where("project_id","==",id)
  groups.get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      doc.ref.delete();
    });
  });

  return projectsCollection.doc(id).delete()
}

export const loadProjects = (uid) => {
  
  const projects = ref([])
  const close = projectsCollection.where("uid","==",uid.value).onSnapshot(snapshot => {
    projects.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return projects
}

export const getProject = async id => {
  const project = await projectsCollection.doc(id).get()
  return project.exists ? project.data() : null
}

export const clearProjectData = (id) => {
  //clear only the tasks data, but keeps their names
  let tasks = tasksCollection.where("project_id","==",id)
  tasks.get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      let tas =[]
      tas = {    
        checked: "", priority: "", status: "", achieved: 0, start_date:  "",
        end_date:    "", t_time: "00:00:00", notes:       "",
      }
      doc.ref.update(tas)
    })
  })
}

 export const duplicateProject = async (pro_data, groups_data, tasks_data) => {
 
  //const projects = ref([])
  // 1. copy the project and get the new project id
  let newProID = 0
  pro_data.name = pro_data.name + "_copy"
  //pro_data.id = ""
  delete pro_data.id 

  await projectsCollection.add(pro_data).then(function(data) {
    projectsCollection.doc(data.id).update({id: data.id }) // add id to the project doc
    pro_data.id = data.id
    newProID = data.id  //get the new created doc id 

    groups_data.forEach((doc) => {
      let current_pro_id = doc.id
      //find all tasks belong to the group
      const group_tasks = tasks_data.filter(x => x.group_id === current_pro_id)

      delete doc.id // delete the current group id 
      doc.project_id = newProID //save the new project id in the group
      //creat the new group
      groupsCollection.add(doc).then(function(g_data) {
        let newGroupID = g_data.id
        //create the tasks
        group_tasks.forEach((doc1) => {
          delete doc1.id // delete the current taks id
          doc1.project_id = newProID  // change the taks project id
          doc1.group_id = newGroupID // change the taks group id
          tasksCollection.add(doc1)  // create the task

        })
      })
    })  
  })
  
  return pro_data
  
}

////////////////////////////////////////////////////////////////////////////////////////////////////
const groupsCollection = db.collection('groups')

export const createGroup = group => {
  return groupsCollection.add(group)
}

export const updateGroup = (id, group) => {
  return groupsCollection.doc(id).update(group)
}

export const deleteGroup = id => {
  var tasks = tasksCollection.where("group_id","==",id)
  tasks.get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      doc.ref.delete();
    });
  });

  return groupsCollection.doc(id).delete()
}

export const useLoadGroups = () => {
  const groups = ref([])
  const close = groupsCollection.onSnapshot(snapshot => {
    groups.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return groups
}

export const loadGroupsByProject = (id) => {
  const groups = ref([])
  //const close = 
  groupsCollection.where("project_id","==",id).orderBy("order").onSnapshot(snapshot => {
    groups.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  //onUnmounted(close)
  return groups
}


////////////////////////////////////////////////////////////////////////
export const createTask = task => {
  return tasksCollection.add(task)
}

export const getTask = async id => {
  const task = await tasksCollection.doc(id).get()
  return task.exists ? task.data() : null
}

export const updateTask = (id, task) => {
  return tasksCollection.doc(id).update(task)
}

export const deleteTask = id => {
  return tasksCollection.doc(id).delete()
}


export const loadTasksByProject = (id) => {
  const tasks = ref([])
  //let tasks_count = 0

  tasksCollection.where("project_id","==",id).orderBy('order').onSnapshot(snapshot => {
    //tasks_count = snapshot.size
    tasks.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    //return tasks_count
  })
  
  return tasks 

 // tasksCollection.where("project_id","==",id).orderBy('order').onSnapshot(snapshot => {
  //  tasks_count = snapshot.size
  //  tasks.value = snapshot.docs.map(doc => (tasks_count,{ id: doc.id, ...doc.data() }))
 //   return tasks_count
//  })
  
 // return {tasks , tasks_count}

}





export const useLoadTasks = () => {
  const tasks = ref([])
  const close = tasksCollection.onSnapshot(snapshot => {
    tasks.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  
  onUnmounted(close)
  return tasks
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const useLoadTasks11111 = () => {
  let gg = []
  
  //const tasks = []
  const close = groupsCollection.onSnapshot(querySnapshot => {
                        //gg = [];
  //groupsCollection.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
          
                      let uni = {
                        id: doc.id,
                        name: doc.data().name,
                        tasks: [],
                      } 
                      tasksCollection.where("group_id","==",doc.id).onSnapshot(querySnap => {
                        querySnap.forEach((doc1) => {
                            //uni.tasks.push(doc1)
                            uni.tasks.push(
                              {
                                  id: doc1.id,
                                  name: doc1.data().name,
                                  achieved: doc1.data().achieved,
                                  recurrence: doc1.data().recurrence,
                                  status:doc1.data().status,
                                  t_time:doc1.data().t_time,
                                  group: doc.data().name,
                              }
                            )

                          })
                      })
                      gg.push(uni)
                    }) 
                  })
  //tasks.value = gg.docs.map(doc => ({ id: doc.id, ...doc.data() }))  

  console.log(close)   
  onUnmounted(close)
  return gg
}

export const useLoadTasks000 = async  () => {
  let gg = []
  //const tasks = []
 // const close = groupsCollection.onSnapshot(querySnapshot => {
                        //gg = [];
  //groupsCollection.get().then((querySnapshot) => {
  const groups = await groupsCollection.get();
    for (const doc of groups.docs) {
        let uni = []
        uni = {
          id: doc.id,
          name: doc.data().name,
          tasks: [],
        } 
        console.log (uni)
        const tasks =  tasksCollection.where("group","==",doc.id)
        
        for (const doc1 of tasks.docs)  {
          uni.tasks.push(
            {
                id: doc1.id,
                name: doc1.data().name,
                achieved: doc1.data().achieved,
                recurrence: doc1.data().recurrence,
                status:doc1.data().status,
                t_time:doc1.data().t_time,
                group: doc.data().name,
            }
          )


        }
        gg.push(uni)  
    }
    console.log (gg)

  //tasks.value = gg.docs.map(doc => ({ id: doc.id, ...doc.data() }))  

  return gg
}

export const useLoadTasks56565 = () => {
  let gg = ref([])
  //const tasks = []
  const close = groupsCollection.onSnapshot(querySnapshot => {
                        //gg = [];
  //groupsCollection.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      let uni = ref([])
                       uni.value = {
                        id: doc.id,
                        name: doc.data().name,
                        tasks: [],
                      }
                      tasksCollection.where("group","==",doc.id).onSnapshot(querySnap => {
                        querySnap.forEach(() => {
                            //uni.tasks.push(doc1)
                            //console.log(doc1)
                            uni.value.tasks.push( querySnap.docs.map(doc1 => ({ id: doc1.id, ...doc1.data() })) )
                        })
                      })
                      gg.value.push(uni)
                    }) 
                  })
                  console.log(gg)
  //tasks.value = gg.docs.map(doc => ({ id: doc.id, ...doc.data() }))  
                 
  console.log(close)   
  //onUnmounted(close)
  return gg
}

export const useLoadTasks2222 = () => {
  let groups_tasks = reactive([])
  //const tasks = ref([])
  let temp_data = reactive([])
  const close = groupsCollection.onSnapshot(querySnapshot => {
  //groupsCollection.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {

                      temp_data = {
                        id: doc.id,
                        name: doc.data().name,
                        tasks: [],
                      }
                      tasksCollection.where("group","==",doc.id).onSnapshot(querySnap => {
                        querySnap.forEach((doc1) => {
                          temp_data.tasks.push(
                            {
                              id: doc1.id,
                              name: doc1.data().name,
                              achieved: doc1.data().achieved,
                              recurrence: doc1.data().recurrence,
                              status:doc1.data().status,
                              t_time:doc1.data().t_time,
                              group: doc.data().name,
                            }
                          )
                            
                          })
                      })
                      
                      groups_tasks.push(temp_data)
                      
                    }) 
                  })
                  console.log(groups_tasks) 
                  //console.log(groups_tasks)  
  //tasks.value = gg.docs.map(doc => ({ id: doc.id, ...doc.data() }))  
  
  //let mm = async () => { await groups_tasks }
 
  //console.log(tasks)   
  onUnmounted(close)
  return groups_tasks
}

///////////
export const getUserSettings = async id => {
  const query = await settingsCollection.where("user_id", "==", id).get()
  //console.log(query.docs[0])
  const settings_data = query.docs[0].data() // return only one doc
  return settings_data 
}


export const updateColumnsStatus = (id, sett) => {
  //return settingsCollection.where("user_id", "==", id).update(sett)
  return settingsCollection.where("user_id", "==", id)
  .get()
  .then(function(querySnapshot) {
    querySnapshot.forEach(function(document) {
     document.ref.update(sett); 
    });
  });

}