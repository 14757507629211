<template>
<div class="offcanvas offcanvas-start p-3" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">

    <div class="offcanvas-header">
        <h2 class="offcanvas-title  d-sm-block" id="offcanvas" 
            style="font-family: var(--bs-body-font-family); color: rgb(92, 82, 162); font-weight: 1000;"> 
          Task 7 
        </h2>

        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" 
            aria-label="Close">
        </button>
    </div>

    <h5 @click="userForm(true)" data-bs-dismiss="offcanvas" class="pointer me-2" style="color: #6054a3; margin-left: 16px; ">
         <i class="fa fa-pen" style=" display: inline-block; color: rgb(121 143 166); font-size:0.9rem"></i> 
         Hiii,{{user_info_name}} 👋 
    </h5>

    <hr>
    
    <div class="offcanvas-body px-0 pt-0">
      
      <h5  style="color: #6054a3; margin-left: 16px; ">Project Tools: 
        <span v-if="selected_project">  {{selected_project.name}}  </span>
      </h5>
      <div v-if="selected_project">
        <div @click="projectForm(true)" data-bs-dismiss="offcanvas" class="pointer ms-3 me-2 mt-2" style=" display: inline-block; color: rgb(121 143 166); font-size:1.05rem">
          <i class="fa-solid fa-gear"></i> Settings
        </div>
        <div @click="clearProject()" data-bs-dismiss="offcanvas" class="pointer me-2 ms-3  mt-2" style=" display: inline-block; color: rgb(121 143 166); font-size:1.05rem">
          <i class="fa-solid fa-broom"></i> Clean
        </div>
        <div @click="cloneProject()" data-bs-dismiss="offcanvas" class="pointer me-2 ms-3  mt-2" style=" display: inline-block; color: rgb(121 143 166); font-size:1.05rem">
          <i class="fa-solid fa-clone"></i> Duplicate
        </div>


        <div class="pointer no-select ms-3 me-2 mt-4" style="display: inline-block; color: rgb(121 143 166); font-size:1.05rem;"
             v-on:click="showTimerMeth()"> 
            <span v-if="selected_project.show_timer ">
              <i class="far fa-bell"></i> Hide Stopwatch
            </span> 
            <span v-show="!selected_project.show_timer">
              <i class="far fa-bell-slash"></i> Show Stopwatch
            </span>
        </div>
        <div class="pointer no-select  ms-3 me-2 mt-4" style="display: inline-block; color: rgb(121 143 166); font-size:1.05rem;"
             v-on:click="showProgressBarMeth()"> 
            <span v-if="selected_project.show_progress_bar ">
              <i class="fas fa-spinner"></i> Hide Progress Bar
            </span> 
            <span v-show="!selected_project.show_progress_bar">
              <i class="fas fa-circle-notch"></i> Show Progress Bar
            </span>
        </div>



      </div>
      <div v-else>
          <h6 class="ms-3">  Select a project to see the tools </h6>
      </div>
      <hr>
      <h5  style="color: #6054a3; margin-left: 16px; ">Projects</h5>
        
      <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">
            <div v-for="(project, index) in projects" :key="project.id">
                  <li class="nav-item pointer">
                        <a @click="loadProjectsGroupsAndTasks(index)" data-bs-dismiss="offcanvas" class="nav-link text-truncate">
                            {{project.name}}
                        </a>
                  </li>
            </div>
      </ul>
      <hr>
      
      <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" >
        <li>
          <router-link to="/" class="nav-link text-truncate" style="color: #6054a3;"> Home</router-link>
        </li>
        <li>
          <a class="nav-link text-truncate" style="color: #6054a3 ;" href="./Task7_manual.pdf">Help</a>
        </li>
        <li>                         
            <a @click="logout" data-bs-dismiss="offcanvas" class=" pointer nav-link text-truncate" style="color: #6054a3 ;">
            Logout
            </a>
        </li>
      </ul>
    </div>
</div>

    <div class="row">

      <div class="col-2">
        <button class="btn btn-secondary btn-sm" style="height: 30px; width:30px;" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
        <i class="fa-solid fa-bars" ></i>
        </button>
      </div>

      <div class="col-8 text-center">
          <button class="btn btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="background-color: white; 
          font-weight: 800; border-color: #5d51a2; color: rgb(92, 82, 162); padding:2px 8px 2px 8px; font-size: inherit;">
            Task7.co
          </button>
      </div>
      <div class="col-2">
        
        <div class="dropdown ms-2">
          <button class="btn btn-secondary btn-sm " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"
                  style="height: 30px; float: right; width:30px;" >   
          <i class="fa-solid fa-plus" ></i>
          </button>

           <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item pointer"  @click="projectForm(false)"> New Project</a></li>

            <li v-if="selected_project" ><a class="dropdown-item pointer" @click="groupForm(false)"> New Group</a></li>
            <li v-else><a class="dropdown-item pointer disabled" > New Group</a></li>

            <li v-if="groups.length > 0" ><a class="dropdown-item pointer" @click="taskForm(false)"> New Task</a></li>
            <li v-else ><a class="dropdown-item pointer disabled" disable> New Task</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div id="abc">
    <div v-if="selected_project && selected_project.show_timer" id="clock" >
      <div class="time" id="time">{{ clock_time }} </div>

        <div v-if="running === 'ongoing' || running === 'paused' " class="task_title mb-3"  id="t-tiltle" style="font-size: 16px; color:#7e3b8a; font-family: Roboto,sans-serif; ">  
           {{edited_task_time.name}}
        </div>

        <div class="text-center" v-if="running === 'ongoing' " >

        <a class="btn btn-success btn-success_timer btn-sm" @click="stop()"> Save </a>
        <a class="btn btn-danger btn-sm" @click="pause()"> Pause </a>
        <a class="btn btn-warning btn-sm" @click="reset()">Reset</a>
        <a class="btn btn-secondary btn-sm" @click="focusmood()">
          <span v-if="!isFocusMood">
                Focus Mood
          </span> 
          <span v-else>
                Exit Focus Mood
          </span>
        </a>
        <a class="btn btn-info btn-sm" @click="fullscreen()">
          <span v-if="!isFullScreen">
                Full Screen
          </span> 
          <span v-else>
                Exit
          </span>
        </a>
        

      </div>
      <div v-else-if="running === 'paused' ">
        <a class="btn btn-success btn-success_timer btn-sm" @click="stop()"> Save </a>
        <a class="btn btn-danger btn-sm" @click="resume()"> Resume </a>
        <a class="btn btn-warning btn-sm" @click="reset()">Reset</a>
        <a class="btn btn-secondary btn-sm" @click="focusmood()">
          <span v-if="!isFocusMood">
                Focus Mood
          </span> 
          <span v-else>
                Exit Focus Mood
          </span>
        </a>
        <a class="btn btn-info btn-sm" @click="fullscreen()">
          <span v-if="!isFullScreen">
                Full Screen
          </span> 
          <span v-else>
                Exit
          </span>
        </a>
      </div>
    </div>
    </div>


    <div v-if="selected_project" class="mt-3" style="display: flex;">

      <div id="project_name" class="text-left" style="flex: 1 0; font-size: 20px; font-weight:bold; color:rgb(92, 82, 162); font-family: Roboto,sans-serif; "> 
        {{selected_project.name}}   

      </div> 
    
    </div> 
  
    <div v-if="tasks_groups.length > 0  && selected_project.show_progress_bar" class="mt-3" >
        <div class="progress progress-bar progress-bar-striped bg-warning" role="progressbar" 
              :style="{width: totalTasks.tasks_completion_percent + '%'}" 
              aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
          <label style="color: black; padding-left: 8px;"> 
            {{ totalTasks.tasks_completion_percent }} % ( {{ totalTasks.tasks_completed }} of {{ totalTasks.tas_count }} Tasks)
          </label> 
        </div>
        <div class="text-center mt-3" style="font-size: 14px; font-weight:bold; color: #444; font-family: Roboto,sans-serif;">       
            ( Day {{pr_current_day_no}} of {{ selected_project.days_number }} )
        </div> 
    </div>

    <div v-if=" groups.length > 0 && selected_project.show_fast_task_addition" class="d-flex mt-3 mb-3" > 
      <input v-model="task_name" type="text" placeholder="Task name" class="form-control"> 
    
      <select v-model="task_group"  class="form-select">
        <option v-for="g in groups" :key="g.id" :value="g.id">
          {{ g.name }}
        </option>
      </select>
      
      <button @click="submitTask" class="btn btn-warning rounded-0"> Add </button>
    </div> 
    
    
  <div v-for="(group, group_index) in groups" :key="group.id" >  

  <div class="table-responsive mt-2">
    <div @click="groupForm(true, group_index)" class="pointer no-select"  id="group_name" style="padding-left: 0px; text-align:left; font-size: 20px; color: rgb(126, 59, 138); margin-bottom: 0px; font-family: Roboto,sans-serif; font-weight:500;" > 
        {{group.name}} 
        <i class="group_tools far fa-edit pointer" style="font-size: 16px;"></i>
    </div>
    <table class="table" width="100%">
      <tr class="text-center" style="height: 30px; background-color: white; border-top: 0px; color:#444; " >

        <th style="min-width: 300px;"> Task </th>
        <th v-show='selected_project.show_checked'> </th>
        <th v-show='selected_project.show_priority' class="pe-4 ps-4 " style="width: 160px;"> Priority </th>
        <th v-show='selected_project.show_status' class="pe-5 ps-5" style="width: 160px;"> Status </th>
        <th v-show='selected_project.show_recurrence' colspan="3" class="pe-4 ps-4"> Recurrence </th>
        <th v-show='selected_project.show_time' class="pe-5 ps-5" style="width: 160px;"> Time </th>
        <th v-show='selected_project.show_start_date' class="pe-2 ps-2" > Start Date </th>
        <th v-show='selected_project.show_end_date' class="pe-2 ps-2" > End Date </th>
        
      </tr>
        <draggable v-model="tasks_groups" tag="tbody" item-key="id"  v-bind="dragOptions" @change="updateTasksOrder" style="border-top-color: #ffffff00">
         
          <template #item="{ element, index }" >
            <template v-if="group.id === element.group_id" > 
              <tr v-show="element.checked !=true || selected_project.show_completed_tasks "  class="tasks_groups_item" id="task_name"  
                    style="height: 25px; vertical-align: middle; border-bottom: 4px solid; border-bottom-color:white;" >
               
 
                <td @click="taskForm(true, index)"  class="pointer no-select pe-2 ps-2" style="border-right-width:4px; border-right-color:white;" 
                    :class="{'completed_status': element.status ==='Completed' || element.checked}" > 
                      {{ element.name }}  
                </td>

                <td  v-show='selected_project.show_checked' @click="changeCheckedStatus(index)" class="pointer no-select" style="text-align:right; border-left-width:4px; border-left-color:white; width: 30px;" >
                    <span v-if="!element.checked">
                          <span class="fas fa-check" style="font-size: larger; color:#dee2e6;"> </span>
                    </span> 
                    <span v-else>
                          <span class="fas fa-check" style="font-size: larger; color:#7e3b8a;"> </span>
                    </span> 
                </td>

                <td v-show='selected_project.show_priority' @click="changePriority(index)" :class="{ 
                        'low_priority-color pointer no-select': element.priority ==='Low',
                        'medium_priority-color pointer no-select': element.priority ==='Medium',
                        'high_priority-color pointer no-select': element.priority ==='High',
                        'no_priority-color pointer no-select': element.priority ===''

                      }" style="border-left-width:4px; border-left-color:white; vertical-align: middle;">
                      {{ element.priority }}
                </td>
                <td v-show='selected_project.show_status' @click="changeStatus(index)" 
                    style="border-left-width:4px; border-left-color:white; vertical-align: middle; min-width: 152px; "
                    :class="{ 
                              'notstarted-color pointer no-select': element.status ==='Not Started',
                              'ongoing-with-me-color pointer no-select': element.status ==='Ongoing > me',
                              'ongoing-with-others-color pointer no-select': element.status ==='Ongoing > others',
                              'completed-color pointer no-select': element.status ==='Completed',
                              'pending-with-me-color pointer no-select': element.status ==='Pending > me',
                              'pending-with-others-color pointer no-select': element.status ==='Pending > others',
                              'cancelled-color pointer no-select': element.status ==='Cancelled',
                              'no_status-color pointer no-select': element.status ===''
                            }" >
                      {{ element.status }}
                </td>

                <td v-show='selected_project.show_recurrence' style="text-align:right; border-left-width:4px; border-left-color:white; width:30px;" >
                  <div  @click="increaseRcurrence(index)" > 
                    <span class="pointer fas fa-plus-circle txt-right no-select" style="font-size: larger; color:#7e3b8a;"> </span>
                  </div>
                </td>
                <td v-show='selected_project.show_recurrence' class="text-center no-select" style="min-width: 100px; width: 100px;">
                    {{ element.achieved }} / {{ element.recurrence }}
                </td>
                <td v-show='selected_project.show_recurrence' style="text-align:left; border-right-width:4px; border-right-color:white; width: 30px;" >
                    <div @click="decreaseRcurrence(index)"> 
                      <span class="pointer fas fa-minus-circle no-select" style="font-size: larger; color: #7e3b8a;" > </span>
                    </div>
                </td>
                <td v-show='selected_project.show_time' class="pointer no-select" style="border-left-width:4px; border-left-color:white;">
                  <div class="text-center" > 
                      <span v-if="edited_task_time_index === index" @click="stop()">
                        <span  class="far fa-stop-circle me-2" style="color: #ea0000; font-size: larger;"> </span>
                        {{clock_time}}  
                      </span>
                      <span v-else @click="playTaskTime(index)">
                        <span  class="far fa-play-circle  me-2" style="color: #0073ea; font-size: larger;"> </span>
                        {{element.t_time}} 
                      </span>

                    </div> 
                </td>
                <td v-show='selected_project.show_start_date' class="text-center no-select" style="border-left-width:4px; border-left-color:white; min-width: 110px;">
                    {{ element.start_date }} 
                </td>
                <td v-show='selected_project.show_end_date' class="text-center no-select" style="border-left-width:4px; border-left-color:white; min-width: 110px;">
                    {{ element.end_date }}
                </td>
     

              </tr>
            </template> 
          </template> 
      </draggable>
    </table>
    </div>
  </div> <!-- end fro loop -->
  <div class="mb-5"> </div>
  <div v-if="userModel">
    <transition name="model" role="dialog" >
      <div class="modal-mask ">
      
          <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title "> 
                  User Details: 
                </h5>
              </div>
              <div class="modal-body mt-4">
                
                <div class="mb-2">
                    <label class="form-label">User Name </label>
                    <input v-model="user_name" class="form-control">
                </div>
                <div class="mb-2">
                    <label class="form-label"> Email </label>
                    <input  v-model="user_email" class="form-control" disabled>
                </div>

              </div>
              <div class="modal-footer mt-4" style="display: block !important; text-align:center;">
                <button type="button" class="btn btn-success" @click="updateUserData()"> Update</button>
                <button type="button" class="btn btn-secondary" @click="userModel=false"> Cancel</button>
              </div>
            </div>
          </div>
      
        </div>
    </transition>
  </div>



  <div v-if="groupsModel">
    <transition name="model" role="dialog" >
      <div class="modal-mask ">
      
        <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title ">  
                  Current Group XXX: 
                  <h5 class="badge rounded-pill btn-primary btn-sm ">  {{group}}  </h5>
                </h5>
              </div>
              <div class="modal-body">
                
                <span v-for="(group) in groups" :key="group.id">
                    <button style="border-radius:10px;" class="btn btn-outline-info mb-1 me-1" 
                            @click="updateTaskGroup(group.id)" > 
                      {{group.name}}
                    </button>
                </span>
              </div>
              <div class="modal-footer" style="display: block !important; text-align:center;">
                <button type="button" class="btn btn-secondary" @click="groupsModel=false" > Close</button>
              </div>
            </div>
          </div>
     
      </div>
    </transition>
  </div>




  <projectDetailModal v-if="showProjectDetailModal" :project_data="project_data"  
                      :project_operation_type="project_operation_type" 
                      :getNewProjectData = "getNewProData"
                      @close="closeProjectDetailModal" />

  <groupDetailModal v-if="showGroupDetailModal" :group_data="group_data"  
                      :group_operation_type="group_operation_type" 
                      :getNewGroupData = "getNewGroData"
                      @close="closeGroupDetailModal" />

   <taskDetailModal v-if="showTaskDetailModal" :task_data="task_data"  
                      :all_groups="all_groups"
                      :task_operation_type="task_operation_type" 
                      :getNewTaskData = "getNewTasData"
                      @close="closeTaskDetailModal" />

</template>

<script>
//import KProgress from 'k-progress';
import firebase from 'firebase';
import draggable from 'vuedraggable';
import {updateProject, updateUser, loadProjects, loadGroupsByProject, clearProjectData, duplicateProject,
 loadTasksByProject, deleteTask, updateTask,createTask } from '@/firebase';
import projectDetailModal from "./projectDetailModal";
import groupDetailModal from "./groupDetailModal";
import taskDetailModal from "./taskDetailModal";
//import firebase from 'firebase'

//import firebase from 'firebase'
export default {
  name: 'TasksPage',
  components: {
    projectDetailModal,
    groupDetailModal,
    taskDetailModal,
    draggable
  },
  data() {
    const projects= loadProjects(this.user_info_id)
    const projects_groups = []// useLoadGroups()
    const tasks_groups = [] //useLoadTasks()

    const groups = []
    return { tasks_groups, deleteTask, updateTask, edited_task:null, index:null, selected_project:null,
            task_name:'',task_group:'', 
            avaliableStatuses: ['Not Started','Ongoing > me','Ongoing > others','Completed','Pending > me','Pending > others', 'Cancelled', ''], avaliablePriorities: ['Low','Medium', 'High',''], 
            task_time:'00:00:00', edited_task_time:null,edited_task_time_index:null,
            isFullScreen: false,
            isFocusMood: false,
            timeBegan: null,
            timeStopped: null,
            stoppedDuration: 0,
            started: null,
            running: false,
            clock_time: '00:00:00',
            projects_groups,
            projects,
            groups,
            group:null,
            groupsModel:false,
            a: 0,
            showProjects: false,
            selected_project_name: '',
            selected_project_id: '',
            clean_interface: false,
            //Project component items
            showProjectDetailModal:false,
            project_data: {}, //this will be used to send data to the projetc form when edit
            project_operation_type:'',
            pr_current_day_no: null,
            //Group component items
            showGroupDetailModal:false,
            group_data: {}, //this will be used to send data to the group form when edit
            group_operation_type:'',
            //Task component items
            showTaskDetailModal:false,
            task_data: {}, //this will be used to send data to the task form when edit
            all_groups:{}, //this will be used to send data to the task form when edit/new
            task_operation_type:'',
            ///////////
            /// dragable feature
            drag: false,
            //user: this.$parent.user,
            /////////////////
            userModel: false,
            user_name:"",
            user_email:"",
            }  
  }, 
  inject: ['user_info_id', 'user_info_name', 'user_info_email'],//bring user datefro app.vue



  watch: {
    selected_project_id(new_project_id){
      //any time we select a project, its id will be ssaved in the boroser memory
      localStorage.project_id=new_project_id;
    },

    projects(new_projects){ // projects is this.projects and new_projects is the projests list after loading or updates
      // this will be excuted when the list filled with the project names
      // so, whne we refresh the page and found a project_id storeed in the browser memory
      // we will find the index number that match the project_id and will load the project details 
      // based on that
 
      if (localStorage.project_id && this.project_operation_type === ''){
        for(let i=0; i < new_projects.length; i++){
          if (new_projects[i].id == localStorage.project_id ) {
            // we use the index, to avoid calling the database again to get the basic project details
            this.loadProjectsGroupsAndTasks(i)
            i= new_projects.length
          }
        }
      }
    }

  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
       
        ghostClass: "ghost",
        delay: 200
      };
    },
    

    totalTasks: function() {
      let tas_count = this.tasks_groups.length
      let tasks_completed = 0 
      for(let i=0; i < this.tasks_groups.length; i++){
        if (this.tasks_groups[i].checked == true ) {
            tasks_completed ++ 
        }
      }
      let tasks_completion_percent = Math.round(tasks_completed / tas_count * 100)
      return {tas_count, tasks_completed , tasks_completion_percent}
    },
  },
  methods:  {
    logout() {
      
      firebase.auth().signOut().then(() => {
          this.$router.push('/');
        })
        .catch(error => {
          alert(error.message);
          this.$router.push('/');
        });
    },
    updateTasksOrder(eve){
      let group_id =  eve.moved.element.group_id
      this.tasks_groups.map(function(task, index) {
          //console.log(this.tasks_groups.name)
          task.order = index

          
          if (task.group_id == group_id ) {
            //  if (task.id == eve.moved.element.id ) {
              //    task.group_id = group_id
             // }

              updateTask(task.id, task)
          }
      })


    },

    loadProjectsGroupsAndTasks(index){
      //since the project inititlal data already stored in the list when the taks page open
      //then we need only to find the selected project index to get thet project details
      // and after that we will fetch its group from the databace  
      this.selected_project =  this.projects[index]
      
      this.selected_project_id = this.selected_project.id
      this.selected_project_name = this.selected_project.name;
      this.groups = loadGroupsByProject(this.selected_project.id)
      this.tasks_groups  = loadTasksByProject(this.selected_project.id)
      
     // this.$router.push({ name: 'task', params: { id: this.selected_project.id } });

      const d = new Date()
      this.pr_current_day_no = Math.floor((d - Date.parse(this.selected_project.start_date)) / 86400000) +1

      // if any time is on, u should save it
      if (this.running){
        this.running = null
        this.edited_task_time_index = null
        this.edited_task_time.t_time = this.clock_time
        this.updateTaskTime(this.edited_task_time)
        clearInterval(this.started)
        this.clock_time = '00:00:00'
      }

    },

    clearProject(){
      if(confirm("Do you really want to clear the tasks data?")){
        clearProjectData (this.selected_project.id)
      }
    },

    async cloneProject(){
      
      if(confirm("Please, confirm copying the project")){
        this.project_operation_type="clone"
        const duplicated_pro_data = await duplicateProject(this.selected_project, this.groups, this.tasks_groups)
        this.selected_project = duplicated_pro_data
        this.selected_project_id = this.selected_project.id
        this.groups = loadGroupsByProject(this.selected_project_id)
        this.tasks_groups = loadTasksByProject(this.selected_project_id)
         
        const d = new Date()
        this.pr_current_day_no = Math.floor((d - Date.parse(this.selected_project.start_date)) / 86400000) +1
      }

    },

    hideAll(){
      if(this.clean_interface === true) {
        this.clean_interface = false
        this.showProjects = false
        this.showTimer = false
      }else {
        this.clean_interface = true
        this.showProjects = true
        this.showTimer = true
      }
    },
    showProjectsMeth (){
      this.showProjects = !this.showProjects
      if(this.showProjects === true && this.showTimer === true) {
        this.clean_interface = true
      }else {
        this.clean_interface = false
      }
    }, 

    async showTimerMeth (){
      this.selected_project.show_timer = !this.selected_project.show_timer
      await updateProject(this.selected_project.id, {show_timer: this.selected_project.show_timer})
      
    }, 

    async showProgressBarMeth (){
      this.selected_project.show_progress_bar = !this.selected_project.show_progress_bar
      await updateProject(this.selected_project.id, {show_progress_bar: this.selected_project.show_progress_bar})
    },


    // start.... project componet related methods
    closeProjectDetailModal (){
      this.showProjectDetailModal= false
    },

    projectForm(editProject){  
      // if the editProject is true theen we need to get the selected project data
      if(editProject) {
        this.project_data = this.selected_project
        this.project_operation_type = "edit"
      }else {
        this.project_data = []
        this.project_data.uid = this.user_info_id.value 
        
        this.project_operation_type = "new"
      }
      this.showProjectDetailModal = true;
    },

    getNewProData (new_pro_data){
      // this will be excuted throug the projectsDetialModal and after save the project data
      // after saving the projects, it data get updated in the database 
      // but not in the view, so, I had to reasign the new data here again
      this.selected_project = new_pro_data 
      if (new_pro_data === null) {
        return;
      }

      if (this.project_operation_type === "new") {
        this.selected_project_id = this.selected_project.id
        this.groups = loadGroupsByProject(this.selected_project_id )
        this.tasks_groups = loadTasksByProject(this.selected_project_id )
        //this.groups = []
        //this.tasks_groups = []
      } 
      this.showProjectDetailModal= false
      const d = new Date()
      this.pr_current_day_no = Math.floor((d - Date.parse(this.selected_project.start_date)) / 86400000) +1
    },

    // end.... project componet related methods

    // start.... group componet related methods
    closeGroupDetailModal (){
      this.showGroupDetailModal= false
    },

    groupForm(editGroup, index){  
      // if the editProject is true theen we need to get the selected project data
      if(editGroup) {
        this.group_data = this.groups[index]
        this.group_operation_type = "edit"
      }else {
         this.group_data = []
         this.group_data.project_id = this.selected_project.id
         this.group_data.uid = this.selected_project.uid
         this.group_data.order = 1
         this.group_operation_type = "new"  
      }
      this.showGroupDetailModal = true;
    },

    getNewGroData(new_gro_data){
      this.selected_group = new_gro_data 
      this.showGroupDetailModal= false
    },
  

    // end.... Group componet related methods

    // start.... Task componet related methods
    closeTaskDetailModal (){
      this.showTaskDetailModal= false
    },

    taskForm(editTask, task_index){  
      // if the editProject is true theen we need to get the selected project data
      if(editTask) {
        this.task_data = this.tasks_groups[task_index]

        this.all_groups = this.groups
        this.task_operation_type = "edit"
      }else {
        this.task_data = []
        this.all_groups = this.groups
        this.task_data.project_id = this.selected_project.id
        this.task_data.group_id   = []
        this.task_data.uid = this.selected_project.uid
        
        this.task_operation_type  = "new"  
      }
      this.showTaskDetailModal = true;
    },

    getNewTasData(new_tas_data){
      this.selected_task = new_tas_data 
      this.showTaskDetailModal= false
     // this.tasks_groups = loadTasksByProject(this.selected_project.id)
    },

    // end.... Task componet related methods
    async delTask(index) {
        await deleteTask(this.tasks_groups[index].id)
        this.index = null;
        
    },
    userForm( stat ){      
      this.userModel = stat;
      this.user_name = this.user_info_name
      this.user_email = this.user_info_email

    },

    async updateUserData() {
      this.userModel = false;
      await updateUser( this.user_name)
     // this.edited_task.group =  name
    },

    changeGroup( t_index, stat ){      
      this.edited_task = this.tasks_groups[t_index]
      this.group =  this.tasks_groups[t_index].group;
      this.groupsModel = stat;
     // this.edited_task = this.tasks_groups[g_index].tasks[t_index]
      //this.group =  this.tasks_groups[g_index].tasks[t_index].group;
      //this.groupsModel = stat;
    },

    async updateTaskGroup(id) {
      this.edited_task.group_id =  id
      this.groupsModel = false;
      await updateTask(this.edited_task.id, this.edited_task)
     // this.edited_task.group =  name
    },

    async submitTask(){
      if(this.task_name.length === 0 || this.task_group.length === 0 ) {
        alert("Cannot add task, make sure to add a Name & select a Group")
      return;
      }
      let today = new Date()
      let dd = ("0" + ( today.getDate()) ).slice(-2)
      let mm = ("0" + ( today.getMonth()) ).slice(-2)
      let yyyy = today.getFullYear()
      today = yyyy + '-' + mm + '-' + dd 

      //this.index comes from the edTask method and it contains all data of the current edited row
      //create new tak
      await createTask({
        name: this.task_name,
        status: "",
        priority : "",
        recurrence: 1,
        achieved: 0,
        start_date: today,
        end_date: today,
        t_time: '00:00:00',
        order: 1000,
        notes:"",
        group_id:    this.task_group,
        project_id:  this.selected_project.id,
        uid       : this.user_info_id.value

      })
      //this.tasks_groups = loadTasksByProject(this.selected_project.id)

      this.task_name =''
      this.group_id =''
   
    },

   // edTask(index){
      //const t = await getTask(index)
  //    this.edited_task = this.tasks_groups[index] // this will be used in the submitTask method
  //    this.task_name = this.tasks_groups[index].name; // we need to edit it
   //   this.task_status = this.tasks_groups[index].status; // we need to edit it
  //    this.task_recurrence = this.tasks_groups[index].recurrence; // we need to edit it
  //  },

    async increaseRcurrence(index){
        this.tasks_groups[index].achieved = this.tasks_groups[index].achieved + 1
        await updateTask(this.tasks_groups[index].id, this.tasks_groups[index] )
        this.index = null;
    },
    async decreaseRcurrence(index){
        this.tasks_groups[index].achieved = this.tasks_groups[index].achieved - 1
        await updateTask(this.tasks_groups[index].id, this.tasks_groups[index] )
        this.index = null;
    },

    async changePriority(index){
      let newIndex =  this.avaliablePriorities.indexOf(this.tasks_groups[index].priority);
      if(++newIndex >3) newIndex = 0;
      this.tasks_groups[index].priority = this.avaliablePriorities[newIndex];
      await updateTask(this.tasks_groups[index].id, this.tasks_groups[index] )
      this.index = null;
    },

    async changeStatus(index){
      let newIndex =  this.avaliableStatuses.indexOf(this.tasks_groups[index].status)
      
      if(++newIndex >7) newIndex = 0

      if (this.avaliableStatuses[newIndex] === "Completed") {
        this.tasks_groups[index].checked = true
      }else{
        this.tasks_groups[index].checked = false
      }

      this.tasks_groups[index].status = this.avaliableStatuses[newIndex];

      await updateTask(this.tasks_groups[index].id, this.tasks_groups[index] )
      this.index = null;
    },

    async changeCheckedStatus(index){
        this.tasks_groups[index].checked = !this.tasks_groups[index].checked
        await updateTask(this.tasks_groups[index].id, this.tasks_groups[index] )
        this.index = null;
    },

    playTaskTime(index){
      // if any timer is on, u should save it
      if (this.running){
        this.edited_task_time.t_time = this.clock_time
        this.updateTaskTime(this.edited_task_time)       
      }

      clearInterval(this.started);
      this.edited_task_time = this.tasks_groups[index]
      this.edited_task_time_index = index // this index will be used to replace the task time with the current running timer
      
      //let a = this.edited_task_time.t_time
      this.clock_time = this.edited_task_time.t_time
      this.started = setInterval(this.taskClockRunning, 1000);	
      
    },
    
    taskClockRunning(){
      this.running = 'ongoing'
      let strTime = this.clock_time 
      let hour = strTime.slice(0, 2)
      let min = strTime.slice(3, 5)
      let sec = strTime.slice(6, 8)

      let s = parseInt(sec)
      let m = parseInt(min)
      let h = parseInt(hour)
      
      s += 1
      if (s === 60){
        m += 1
        s = 0
        if (m === 60 ){
          h += 1
          m = 0
        }
      } 

      let s_final = 0 + String(s)
      let m_final = 0 + String(m)
      let h_final = 0 + String(h)

      this.clock_time = h_final.slice(-2) + ":" + 
                        m_final.slice(-2) + ":" +
                        s_final.slice(-2) 

      //this.edited_task_time.t_time = this.clock_time
    },

    pause(){
      this.running = 'paused'
      clearInterval(this.started)
    },

    resume(){
      this.running = 'ongoing'
      clearInterval(this.started)
      this.started = setInterval(this.taskClockRunning, 1000);	
    },

    stop(){
      this.running = null
      this.edited_task_time_index = null
      clearInterval(this.started)
      this.edited_task_time.t_time = this.clock_time
      this.updateTaskTime(this.edited_task_time)
      this.exitFullscreen()
      if(this.isFocusMood) { this.focusmood() }

      this.clock_time = '00:00:00'
      this.edited_task_time = null

    },
    reset() {
      if(confirm("Confirm RESETTING the stopwatch?")){
        this.clock_time = '00:00:00'
        clearInterval(this.started)
        this.started = setInterval(this.taskClockRunning, 1000);
       }
  
    },
    async updateTaskTime(edited_task){
      //this.edited_task_time.t_time = this.clock_time
       await updateTask(edited_task.id, edited_task)

    },

    exitFullscreen() {

      if (document.exitFullscreen) {
          document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
      }
      this.isFullScreen = false
      
    },


    fullscreen() {
      var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
          (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
          (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
          (document.msFullscreenElement && document.msFullscreenElement !== null);

      var docElm = document.getElementById("clock");

      if (!isInFullScreen) {
          if (docElm.requestFullscreen) {
              docElm.requestFullscreen();
          } else if (docElm.mozRequestFullScreen) {
              docElm.mozRequestFullScreen();
          } else if (docElm.webkitRequestFullScreen) {
              docElm.webkitRequestFullScreen();
          } else if (docElm.msRequestFullscreen) {
              docElm.msRequestFullscreen();
          }
          this.isFullScreen = true
      } else {
          if (document.exitFullscreen) {
              document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
          }
          this.isFullScreen = false
      }
    },

  focusmood(){
    var element = document.getElementById("clock");
    var element2 = document.getElementById("time");
    var element3 = document.getElementById("t-tiltle");
    
    if(!this.isFocusMood){
      element.classList.add("focus-mood");
      element2.classList.add("focus-mood-time");
      element3.classList.add("focus-mood-task-title");
      this.isFocusMood = true
    }else{
      element.classList.remove("focus-mood");
      element2.classList.remove("focus-mood-time");
      element3.classList.remove("focus-mood-task-title");
      this.isFocusMood = false
    }
  },

////////////////////////////////////////////////////////////
//Unused methods for now

///////////
    start() {
      if(this.running) return;
      if (this.timeBegan === null) {
        this.reset();
        this.timeBegan = new Date();//"Sun Sep 26 2021 00:00:00";// 
      }
      if (this.timeStopped !== null) {
        this.stoppedDuration += (new Date() - this.timeStopped);
        console.log(this.timeBegan);
      }
      this.started = setInterval(this.clockRunning, 10);	
      this.running = true;
    },

    reset2222222() {
      this.running = false;
      clearInterval(this.started);
      this.stoppedDuration = 0;
      this.timeBegan = null;
      this.timeStopped = null;
      this.clock_time = "00:00:00";
    },

    stop222222222222() {
      this.running = false;
      this.timeStopped = new Date();
      clearInterval(this.started);
    },

    clockRunning22222(){
      var currentTime = new Date()
      , timeElapsed = new Date(currentTime - this.timeBegan - this.stoppedDuration)
      , hour = timeElapsed.getUTCHours()
      , min = timeElapsed.getUTCMinutes()
      , sec = timeElapsed.getUTCSeconds();
      //, ms = timeElapsed.getUTCMilliseconds();
      this.clock_time = 
        this.zeroPrefix(hour, 2) + ":" + 
        this.zeroPrefix(min, 2) + ":" + 
        this.zeroPrefix(sec, 2);// + ".";// + 
        //this.zeroPrefix(ms, 3);
    },
    zeroPrefix222(num, digit) {
      var zero = '';
      for(var i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },   



  },//methods end


}
</script>


<!--     Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .focus-mood{
    height: initial;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: white;
    position: fixed;
    margin-left:-16px;
  }
  .focus-mood-time{
    font-size: 600% !important;
    margin-top: 30px;
  }
  .focus-mood-task-title{
    font-size: 36px !important;
    margin-bottom: 2rem!important;
  }
/*.btn.table>:not(caption)>*>* {
    background-color: #f5f6f800 ;
  }
*/
  tr{
    background-color: #f5f6f8 ;
  }

  .pointer{
    cursor: pointer;
  }
  .btn{
    border-radius: 0px;
  }
  .form-control{
    border-radius: 0px;
  }


.completed_status {
  background-color: rgb(51 211 145);
  color: white;
}
  .low_priority-color {
    background-color: #c7b5ec; /* rgb(162 93 220);*/
    color: white;
    text-align: center;
  }
  .medium_priority-color {
    background-color: rgb(171 137 238); /*rgb(154 121 253); */
    color: white;
    text-align: center;
  }
  .high_priority-color {
    background-color: rgb(120, 75, 209); /* rgb(211 51 124); */
    color: white;
    text-align: center;
  }    
  .no_priority-color {
    background-color: #d6d6d6;
  }  

  .completed-color {
    background-color: rgb(51 211 145);
    color: white;
    text-align: center;
  }

  .pending-with-me-color {
    background-color: rgb(247 116 71); /* rgb(211 51 124);/* rgb(255 100 46); */
    color: white;
    text-align: center;
  }
  .pending-with-others-color {
    background-color: rgb(249 66 0); /* rgb(211 51 124);/* rgb(255 100 46); */
    color: white;
    text-align: center;
  }
  .notstarted-color {
    background-color: #c4c4c4; /* rgb(162 93 220);*/
    color: white;
    text-align: center;
  }

  .ongoing-with-me-color {
    background-color: rgb(121 175 253);
    color: white;
    text-align: center;
  }
  .ongoing-with-others-color {
    background-color: rgb(46 128 245);
    color: white;
    text-align: center;
    
  }
  .cancelled-color {
    background-color: rgb(37 37 37);
    color: white;
    text-align: center;
    
  }

  .no_status-color {
    background-color: #d6d6d6;
  }  

  /*  /////// Clock Style ////////*/
  #clock {
    margin-top: 1px;
    text-align: center;
    color: #444;
  }

  .time {
    font-size: 5rem;
  }

  .btn-container{
     font-size: 2rem; 
  }

  :-webkit-full-screen .btn{
    /* font-size: 24px !important; */
    width: 136px;
  }
  :-ms-fullscreen .btn{
    /* font-size: 24px !important; */
    width: 136px;
  }
  :fullscreen .btn{
    /* font-size: 24px !important; */
    width: 136px;
  }
  
  :fullscreen .time{
    font-size: 1000% !important;
  }

  :fullscreen .task_title{
    font-size: 36px !important;
    margin-bottom: 1.5rem!important;
    /* margin-bottom: 64px !important; */
  }

  /* Safari syntax */
:-webkit-full-screen {
  padding-top: 2% !important; 
  background-color: #eeeeee; 
 /*  font-size: 2500% !important; */
}

/* IE11 */
:-ms-fullscreen {
  padding-top: 2% !important; 
  background-color: #eeeeee;
/*  font-size: 2500% !important; */
}

/* Standard syntax */
:fullscreen {
  padding-top: 1.5% !important;
  background-color: #eeeeee; 
 /* font-size: 2500% !important; */
}

.btn-info{
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  
}
.btn-success_timer{
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}

.modal-mask {
  display: none; 
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto; 
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  /* Add these lines for centering */
  display: flex;
  align-items: center;
  justify-content: center;
  padding:24px;
}
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 24px;
  border: 1px solid #888;
 
}
/*
.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
    padding: 15px;
    background-color: white;
    width: 80%;
    left: 0;
}
*/
#group_name .group_tools{
    visibility: hidden;
}
#group_name:hover .group_tools{
    visibility: visible;
    color: gray;
}

#task_name .task_tools{
    visibility: hidden;
   
}
#task_name:hover .task_tools{
    visibility: visible;
    color: gray;
    
}

#project_name .project_tools{
    visibility: hidden;
   
}
#project_name:hover .project_tools{
    visibility: visible;
    color: gray;
    font-size: 21px;
}

.no-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.ipc-title {
    background: #7e3b8a;
    width: 4px;
}

/* for dragable list */
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  color: purple;
  font-weight: bolder;
}
.tasks_groups {
  min-height: 20px;
}

.tasks_groups_item i {
  cursor: pointer;
}

.fav-img {
  width: 30px;
  height: 30px;
}

.form-select{
  border-radius: 0px;
  margin-right: 5px;
  margin-left: 5px;
  width: 100px;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

</style>