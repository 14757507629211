<template>
      <div class="modal-mask"  style="display: block;" aria-modal="true" role="dialog">
          <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" >                                       
            <div class="modal-content ">
              <div class="modal-header">
                <h5 class="modal-title "> 
                  Task Details
                </h5>
              </div>
              <div class="modal-body ">
                <div class="mb-2">
                    <label class="form-label">Task Name </label>
                    <input v-model="ta_name" class="form-control">
                </div>

                
                <div class="mb-2">
                    <label  class="form-label">Group </label>
                    <select v-model="ta_group_id"  class="form-select" >
                        <option disabled value="">Select Group</option>
                        <option v-for="g in all_groups" :key="g.id" :value="g.id">
                        {{ g.name }}
                        </option>
                    </select>
                </div>  

                

                <button class="btn btn-primary btn-sm mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    More
                </button>

                <div class="collapse" id="collapseExample">
                    <div class="mb-2">
                        <label class="form-label">Recurrence</label>
                        <input v-model="ta_recurrence" type="number" class="form-control">
                    </div>   
                    <div class="mb-2">
                        <label class="form-label">Start Date </label>
                        <input v-model="ta_s_date" type="date" class="form-control">
                    </div>   
                    <div class="mb-2">
                        <label  class="form-label">End Date </label>
                        <input v-model="ta_e_date"  type="date" class="form-control">
                    </div>  

                    <label  class="form-label me-3 mt-1">Timer </label>
                    <div v-show=" task_operation_type !== 'New' " class="input-group  mb-2 ">
                        <label class="form-label me-3 mt-1"> Hrs: </label>  <input v-model="h" type="number" class="form-control me-2">
                        <label class="form-label me-3 mt-1"> Min: </label> <input v-model="m" type="number" class="form-control me-2">
                        <label class="form-label me-3 mt-1"> Sec: </label> <input v-model="s" type="number" class="form-control">
                
                    </div>  
                </div>
                <div class="mb-2">
                    <label  class="form-label">Notes</label>
                    <textarea v-model="ta_notes" type="text" class="form-control" rows="5"></textarea>
                </div>
              </div>
              <div class="modal-footer" style="display: block !important; text-align:center;">
                <button type="button" class="btn btn-success btn-sm" @click="submitTask()" > Save</button>
                <button type="button" class="btn btn-secondary btn-sm" @click="$emit('close')" > Close</button>
                <button v-if="task_data.id" type="button" class="btn btn-danger btn-sm" @click="deleteTa(); $emit('close') "> Delete</button>
              </div>
            </div>
          </div>
      </div>

</template>

<script>
import { createTask, updateTask, deleteTask } from '@/firebase'
//import {  ref } from 'vue'

export default {
    props:{
        task_operation_type: String,
        task_data: Object, // will be used to get the edited group details from the parent component
        all_groups: Object,
        getNewTaskData: Function,
    },
    emits: ['close'], 
    data() {
        return { 
            ta_id:          this.task_data.id, 
            ta_name:        this.task_data.name, 
            ta_recurrence:  this.task_data.recurrence,
            ta_s_date:      this.task_data.start_date,
            ta_e_date:      this.task_data.end_date,
            ta_t_time:      this.task_data.t_time,
            ta_notes:       this.task_data.notes,
            ta_group_id:    this.task_data.group_id,
            ta_project_id:  this.task_data.project_id,
            ta_uid:         this.task_data.uid,
            //timer
            
            h: 0,//parseInt(this.task_data.t_time.slice(0,2)),
            m: 0,//parseInt(this.task_data.t_time.slice(3,5)),
            s: 0,//parseInt(this.task_data.t_time.slice(6)),
            
            new_task_data:{},

        }
    },
    created() {
        
        if(this.task_operation_type !== "new"){ 
            this.h= parseInt(this.task_data.t_time.slice(0,2))
            this.m= parseInt(this.task_data.t_time.slice(3,5))
            this.s= parseInt(this.task_data.t_time.slice(6))

        }else{
            let today = new Date()
            let dd = ("0" + ( today.getDate()) ).slice(-2)
            let mm = ("0" + ( today.getMonth()) ).slice(-2)
            let yyyy = today.getFullYear()
            today = yyyy + '-' + mm + '-' + dd 
            this.ta_s_date =    today
            this.ta_e_date =    today

            this.ta_recurrence = 1
            this.ta_notes = ""
        }
            
    },
    methods: {
        async deleteTa() {
            if(confirm("Do you really want to delete the task?")){
                await deleteTask(this.task_data.id)
                this.getNewTaskData("")
            }
        },

        async submitTask(){
            if(this.ta_name.length === 0 || this.ta_group_id.length === 0 ) {
                alert("Cannot add task, make sure to add a Name & select a Group")
                return;
            }
            if(this.ta_recurrence < 0 || this.ta_recurrence==="" || isNaN(this.ta_recurrence)){
                this.ta_recurrence = 1
            } 

            this.new_task_data = {    
                                      name:        this.ta_name,
                                      recurrence:  this.ta_recurrence,
                                      start_date:  this.ta_s_date,
                                      end_date:    this.ta_e_date,
                                      notes:       this.ta_notes,
                                      group_id:    this.ta_group_id,
                                      project_id:  this.ta_project_id,
                                      uid       :  this.ta_uid
                                    }

            if (this.task_operation_type === "new") { 
                //create new project
                let newTasID = 0
                this.new_task_data.status = ""
                this.new_task_data.priority = ""
                this.new_task_data.achieved = 0
                this.new_task_data.t_time ="00:00:00"
                this.new_task_data.order = 1000

                await createTask(this.new_task_data ).then(function(data) {
                                            newTasID = data.id  //get the new created doc id 
                                    })

                this.new_task_data.id = newTasID           
                this.getNewTaskData(this.new_task_data)

            } else {
                this.new_task_data.id = this.task_data.id   
    
                if(this.h.toString().length == 0){
                    this.h = "00"
                } else if (this.h.toString().length == 1){
                    this.h = "0" + this.h
                } else { 
                    this.h =  this.h.toString().slice(0,2)
                }
                if(this.m.toString().length == 0){
                    this.m = "00"
                } else if (this.m.toString().length == 1){
                    this.m = "0" + this.m
                } else { 
                    this.m =  this.m.toString().slice(0,2)
                }
                if(this.s.toString().length == 0){
                    this.s = "00"
                } else if (this.s.toString().length == 1){
                    this.s = "0" + this.s
                } else { 
                    this.s =  this.s.toString().slice(0,2)
                }

                this.new_task_data.t_time = this.h + ":" + this.m + ":" + this.s

                await updateTask( this.task_data.id, this.new_task_data)
                this.getNewTaskData(this.new_task_data)
            }

            this.ta_id=''        
            this.ta_name=''        
            this.ta_recurrence=''
            this.ta_s_date=''
            this.ta_e_date=''
            this.ta_notes=''
            this.ta_group_id=''
            this.ta_project_id=''
            this.ta_uid=''
            this.h='' 
            this.m='' 
            this.s='' 


        },

    },//end methods
}

</script>
<style scoped>
.btn{
  border-radius: 0px;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 24px;
  border: 1px solid #888;
 
}
</style>