<template>


<main>

  <section class="py-3 mb-5 text-center container">
    <div class="row py-lg-3">
      <div style="color: #5c52a2;">
        <h2> 
            The only "PERSONAL" Tasks Management app
        </h2>

        <h3 class="mt-3"> 
             YOU will ever need
        </h3>
        <h3 class="mt-3"> 
        الموقع الوحيد الذي ستحتاجه لإدارة مهامك الشخصية
        </h3>
      </div>
    </div>
  </section>
 



<div class="content-area-l-13-1 position-relative overflow-hidden mb-5" style="color: #5c52a2;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="border-bottom">
              <div class="row justify-content-center mb-5">
                <div class="col-xl-12">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-lg-4 col-md-8 col-sm-10 order-lg-1 order-1 mt-3" >
                      <div class="content section-heading-7 text-center">
                        <h2 class="mb-4" > Complex vs Simple, it’s all about your needs </h2>
                        <h3>من الوضع المعقد إلى البسيط، كل شيء يعتمد على احتياجاتك</h3>
                      </div>
                    </div>
                    <div class="text-center col-lg-8 col-md-8 col-sm-10 position-relative order-lg-1 order-0 mt-3">
                        <img style="width: 100%;" :src="'/complex_to_simple.png'"> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>

<div class="content-area-l-13-2 position-relative overflow-hidden mb-5" style="color: #5c52a2;">
      <div class="container ">
        <div class="row justify-content-center ">
          <div class="col-md-11 ">
            <div class="border-bottom ">
              
              <div class="row justify-content-center mb-5">
                <div class="col-xl-12">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="text-center col-lg-8 col-md-8 col-sm-10 mt-3" >
                     
                        <img style="width: 100%;" :src="'/add_task.png'"> 
                      
                    </div>

                    <div class="col-lg-4 col-md-8 col-sm-10 mt-3 " >
                      <div class="content section-heading-7 text-center">
                        <h2  class="mb-4" > Add task easily</h2>
                        <h3> أضف المهام بسهولة</h3>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>


<div class="content-area-l-13-1 position-relative overflow-hidden mb-5" style="color: #5c52a2;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="border-bottom">
              <div class="row justify-content-center mb-5">
                <div class="col-xl-12">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-lg-4 col-md-8 col-sm-10 order-lg-1 order-1 mt-3" >
                      <div class="content section-heading-7 text-center">
                        <h2  class="mb-4" > Simple to use stopwatch </h2>
                        <h3> ساعة إيقاف سهلة الاستخدام</h3>
                      </div>
                    </div>
                    <div class="text-center col-lg-8 col-md-8 col-sm-10 position-relative order-lg-1 order-0 mt-3">
                        <img style="width: 100%;" :src="'/stopwatch.png'"> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>

<div class="content-area-l-13-2 position-relative overflow-hidden mb-5" style="color: #5c52a2;">
      <div class="container ">
        <div class="row justify-content-center ">
          <div class="col-md-11 ">
            <div class="border-bottom ">
              <div class="row justify-content-center mb-5">
                <div class="col-xl-12">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="text-center col-lg-8 col-md-8 col-sm-10 mt-3" >
                     
                        <img style="width: 50%;" :src="'/advanced_tools.png'"> 
                      
                    </div>

                    <div class="col-lg-4 col-md-8 col-sm-10 mt-3" >
                      <div class="content section-heading-7 text-center">
                        <h2  class="mb-4" > Advanced tools to control and customize your projects </h2>
                        <h3>  أدوات متقدمة للتحكم بالمشاريع </h3>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>




  <div class="row text-center col-md-12 mt-3 mb-3 pe-1 ps-1">
    <h2 class="mb-3" style="color: #5c52a2;"> Founder & Developer </h2>
    <div class="col-lg-12">
      <img class="bd-placeholder-img rounded-circle" width="140" height="140" :src="'/linkedin_profile.jpg'"> 
      <h2 class="mt-3">Yousef Alsalem</h2>
      <h6 style="color: #5c52a2;">
        Passionate Human Resources professional with a robust technical skills
      </h6>
      <a class="btn " href="//www.linkedin.com/in/yousef-alsalem" >
        <i class="fab fa-linkedin fa-w-14 fa-2x" style="color: #0b67c2;"> </i>
      </a>
    </div>
  </div>

</main>

<footer class="text-muted py-5 border-top">
  <div class="container">
    <p class="float-end mb-1">
      <a href="#">Back to top</a>
    </p>
    <p class="mb-1">
      Copyright © Task7.co <span v-text="getCurrentYear()"> </span>. All rights reserved.
    </p>
    
  </div>
</footer>




</template>



<script>

//import UserList from '@/components/UserList.vue'
//import TaskCreate from '@/components/TaskCreate.vue'



export default {
  name: 'HomePage',
  methods: {
    getCurrentYear(){
        return new Date().getFullYear();
    }

  }

}
</script>

<style>

body{
  overflow:  auto !important;
}


</style>